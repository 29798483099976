import * as Yup from 'yup'

import { MAX_LENGTHS } from './constants'

export const VALIDATORS = {
  required: Yup.string().required('Required'),
  email: Yup.string().required('Email'),
  requiredEmail: Yup.string().required('Required').email('Invalid email'),
  name: Yup.string()
    .required('Required')
    .max(
      MAX_LENGTHS.name,
      `Name should contain less than ${MAX_LENGTHS.name} characters`
    ),
  description: Yup.string().max(
    MAX_LENGTHS.description,
    `Description should contain less than ${MAX_LENGTHS.description} characters`
  ),
  number: Yup.number().required('Required'),
  positiveNumber: Yup.number()
    .required('Required')
    .positive('Should be positive value'),
  positiveNumberOrZero: Yup.number()
    .required('Required')
    .min(0, 'Should be greater than 0'),
  fileArrayMinLengthOne: Yup.array().min(1, 'Document required'),
}
