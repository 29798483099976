import React from "react";
import BoatImg from "../assets/img/boat.jpg";
import { Text } from "samespace-zen";

function ErrorPage() {
  return (
    <article className="w-full flex items-center justify-center h-screen">
      <section className="flex flex-col gap-y-2 items-center">
        <img src={BoatImg} alt="Sinking Boat" className="mx-auto mb-0.5" />
        <Text label="We’re sorry" size="lg" weight="medium" />
        <Text
          label="Something went wrong with your request. Please try again later or
              contact us."
        />
      </section>
    </article>
  );
}

export default ErrorPage;
