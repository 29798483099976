import React from "react";
import ReviewTitle from "../shared/ReviewTitle";
import LocalPricing from "./LocalPricing";
import OutboundPricing from "./OutboundPricing";
import TollFreePricing from "./TollFreePricing";
import TollFreeSourcePricing from "./TollFreeSourcePricing";

function VoipProduct() {
  return (
    <>
      <ReviewTitle title="Review Changes" />
      <OutboundPricing />
      <LocalPricing />
      <TollFreePricing />
      <TollFreeSourcePricing />
    </>
  );
}

export default VoipProduct;
