import React, { useContext } from "react";
import { Text } from "samespace-zen";
import { ReviewDataContext } from "..";
import { IconDollar } from "../../../assets/productIcons";

const ProfServices = () => {
  const {
    order,
    currency_code: currencyCode,
    currency_iso_code: isoCode,
  } = useContext(ReviewDataContext);
  const data = order?.professional_services;

  return (
    <div className="w-full mx-auto mt-16 mb-0">
      {data?.length ? (
        <div className="pb-4 border-b-[1px] border-solid border-opacity-[0.08] border-black">
          <Text
            label="Professional Services"
            weight="medium"
            size="xl"
            color="muted"
          />
        </div>
      ) : null}
      {data?.map(({ name, amount }, id) => {
        return (
          <div
            className="flex justify-between py-5 border-b-[1px] border-solid border-opacity-[0.08] border-black"
            key={id}
          >
            <div className="flex items-center gap-x-4">
              <IconDollar />
              <Text label={name} weight="bold" />
            </div>
            <div>
              <Text
                label={new Intl.NumberFormat(`en-${isoCode}`, {
                  style: "currency",
                  currency: currencyCode,
                  maximumSignificantDigits: 3,
                }).format(Number(amount).toFixed(2))}
                weight="medium"
                className="mr-1"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProfServices;
