import React from "react";
import { IconNavHeader } from "../assets/productIcons";
import { Text } from "samespace-zen";
import classNames from "classnames";

const OnboardHeader = ({
  showProductName,
  leftAlign = false,
  productName = "",
  width = "520px",
}) => {
  return (
    <div className="w-full shadow-[0_2px_4px_rgba(0,0,0,0.06)] flex items-center py-4 justify-center">
      <div
        className={classNames(`flex w-[${width}]`, {
          "justify-center": !showProductName && !leftAlign,
          "justify-start": leftAlign,
        })}
      >
        <IconNavHeader />
        {showProductName && (
          <>
            <div className="w-[1px] bg-black opacity-[0.08] h-6 mx-3"></div>
            <Text label={productName} color="muted" />
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardHeader;
