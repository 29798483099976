import React from "react";
import ReviewTitle from "../shared/ReviewTitle";
import Metered from "./Metered";
import ProfServices from "./ProfServices";
import Subscriptions from "./Subscriptions";

function SpaceProduct() {
  return (
    <>
      <ReviewTitle
        title="Review your order"
        description="If you have any questions about pricing, please contact your account manager."
      />
      <Subscriptions />
      <Metered />
      <ProfServices />
    </>
  );
}

export default SpaceProduct;
