import React from "react";
import { Text } from "samespace-zen";

function ReviewTitle({ title, description = false }) {
  return (
    <div className="pb-8 border-b-[1px] border-solid border-opacity-[0.08] border-black w-full mx-auto my-0">
      <Text label={title} weight="bold" size="xl" />
      {description ? (
        <Text label={description} color="muted" size="sm" />
      ) : null}
    </div>
  );
}

export default ReviewTitle;
