import { Router, Switch, Route, Redirect } from "react-router-dom";
import { App as AppWrapper, Loader, Toast } from "samespace-zen";
import React from "react";

import "./style.generated.css";
import Onboarding from "./pages/Onboarding";
import ReviewOrder from "./pages/ReviewOrder";

const REVIEW_PATHS = [
  "/new_voip",
  "/new_space",
  "/subscription",
  "/default/local",
  "/default/tollfree",
  "/default/outbound",
];

function App() {
  return (
    <>
      <Toast />
      <AppWrapper>
        {({ history }) => {
          return (
            <>
              <Router history={history}>
                <Switch>
                  <Route exact path="/onboarding">
                    <Onboarding />
                  </Route>
                  <Route path={REVIEW_PATHS}>
                    <ReviewOrder />
                  </Route>
                </Switch>
              </Router>
            </>
          );
        }}
      </AppWrapper>
    </>
  );
}

export default App;
