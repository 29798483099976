import React, { useContext } from "react";
import { Text } from "samespace-zen";
import { ReviewDataContext } from "..";
import { PRODUCT_ICONS } from "../../../utils/constants";

const Subscriptions = () => {
  const {
    order,
    currency_code: currencyCode,
    currency_iso_code: isoCode,
  } = useContext(ReviewDataContext);
  const data = order?.subscriptions;

  const priceInfo = (quantity, cycle, unit, amount) => {
    let price = new Intl.NumberFormat(`en-${isoCode}`, {
      style: "currency",
      currency: currencyCode,
    }).format(+amount);
    let unitCycle = cycle === "Monthly" ? "mo" : "yr";
    return `${quantity} Licenses at ${price} per ${unit.slice(
      0,
      -1
    )}/${unitCycle}`;
  };

  return (
    <div className="w-full mx-auto mt-8 mb-0">
      {data?.length ? (
        <div className="pb-4 border-b-[1px] border-solid border-opacity-[0.08] border-black">
          <Text label="Subscriptions" weight="medium" size="xl" color="muted" />
        </div>
      ) : null}
      {data?.map(
        (
          {
            product_name,
            quantity,
            total_mrc_amount,
            billing_cycle,
            unit_label,
            mrc,
            sub_product_id,
          },
          id
        ) => {
          return (
            <div
              className="flex justify-between py-5 border-b-[1px] border-solid border-opacity-[0.08] border-black"
              key={id}
            >
              <div className="flex items-center gap-x-4">
                <div
                  className="rounded-full flex justify-center items-center w-8 h-8 min-w-8"
                  style={{
                    backgroundColor: PRODUCT_ICONS[sub_product_id]?.bgColor,
                  }}
                >
                  {PRODUCT_ICONS[sub_product_id]?.icon}
                </div>
                <div className="flex flex-col">
                  <Text label={product_name} weight="bold" />
                  <Text
                    label={priceInfo(quantity, billing_cycle, unit_label, mrc)}
                    color="muted"
                    size="sm"
                  />
                </div>
              </div>
              <div>
                <Text
                  label={new Intl.NumberFormat(`en-${isoCode}`, {
                    style: "currency",
                    currency: currencyCode,
                  }).format(+total_mrc_amount)}
                  weight="medium"
                />
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default Subscriptions;
