import React, { useContext } from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "samespace-zen";
import { ReviewDataContext } from "..";
import ReactCountryFlag from "react-country-flag";
import { payloadFromParams } from "../../../utils/request";
import classNames from "classnames";
import TableHeader from "./TableHeader";

function LocalPricing() {
  const {
    pricing,
    currency_iso_code,
    currency_code,
    rates: defaultRates,
  } = useContext(ReviewDataContext);
  const data = pricing?.local_number_price;

  const priceInfo = (rate) => {
    let price = new Intl.NumberFormat(`en-${currency_iso_code}`, {
      style: "currency",
      currency: currency_code,
    }).format(+rate);
    return price.replace(/\.00$/, "");
  };

  return (
    <>
      {(data || defaultRates)?.length ? (
        <div className="w-full mx-auto mt-12 mb-0">
          <div className="pb-4 flex items-center justify-between">
            <Text
              label="Local Number Pricing"
              weight="medium"
              size="xl"
              color="muted"
            />
            <Text
              label="View Default Rates"
              color="primary"
              size="sm"
              className={classNames("cursor-pointer", {
                hidden: defaultRates?.length,
              })}
              onClick={() =>
                window.open(
                  `/default/local?payload=${payloadFromParams}`,
                  "_blank"
                )
              }
            />
          </div>
          <Table>
            <TableHeader
              columns={[
                "country",
                "number mrc/nrc",
                "rate/min",
                "channel mrc/nrc",
              ]}
              colSizes={["35%", "25%", "20%", "20%"]}
            />
            <TableBody>
              {(data || defaultRates).map(
                ({
                  country_iso,
                  rate_per_min,
                  mrc,
                  nrc,
                  channel_mrc,
                  channel_nrc,
                  country,
                }) => {
                  return (
                    <TableRow key={country_iso}>
                      <TableCell className="pl-2" width="35%">
                        <div className="flex items-center justify-start gap-x-2">
                          <ReactCountryFlag
                            svg
                            aria-label={country_iso}
                            countryCode={country_iso}
                            style={{
                              width: "1.5rem",
                              height: "1rem",
                            }}
                          />
                          <Text label={country} />
                        </div>
                      </TableCell>
                      <TableCell width="25%">
                        <Text label={`${priceInfo(mrc)}/${priceInfo(nrc)}`} />
                      </TableCell>
                      <TableCell width="20%">
                        <Text label={priceInfo(rate_per_min)} weight="medium" />
                      </TableCell>
                      <TableCell width="20%">
                        <Text
                          label={`${priceInfo(channel_mrc)}/${priceInfo(
                            channel_nrc
                          )}`}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      ) : null}
    </>
  );
}

export default LocalPricing;
