import React, { useContext } from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "samespace-zen";
import { ReviewDataContext } from "..";
import ReactCountryFlag from "react-country-flag";
import { payloadFromParams } from "../../../utils/request";
import classNames from "classnames";
import TableHeader from "./TableHeader";

function OutboundPricing() {
  const {
    pricing,
    currency_iso_code,
    currency_code,
    rates: defaultRates,
  } = useContext(ReviewDataContext);
  const data = pricing?.outbound_price;

  const priceInfo = (rate) => {
    let price = new Intl.NumberFormat(`en-${currency_iso_code}`, {
      style: "currency",
      currency: currency_code,
    }).format(+rate);
    return price.replace(/\.00$/, "");
  };

  return (
    <>
      {(data || defaultRates)?.length ? (
        <div className="w-full mx-auto mt-8 mb-0">
          <div className="pb-4 flex items-center justify-between">
            <Text
              label="Outbound Pricing"
              weight="medium"
              size="xl"
              color="muted"
            />
            <Text
              label="View Default Rates"
              color="primary"
              size="sm"
              className={classNames("cursor-pointer", {
                hidden: defaultRates?.length,
              })}
              onClick={() =>
                window.open(
                  `/default/outbound?payload=${payloadFromParams}`,
                  "_blank"
                )
              }
            />
          </div>
          <Table>
            <TableHeader
              columns={[
                "destination",
                "rate/min",
                "connection charge",
                "incrementals",
              ]}
              colSizes={[null, "22%", "25%", "16%"]}
            />
            <TableBody>
              {(data || defaultRates).map(
                ({
                  iso_code,
                  destination,
                  rate_per_min,
                  connection_charge,
                  increment,
                  interval,
                }) => {
                  return (
                    <TableRow key={iso_code}>
                      <TableCell className="pl-2">
                        <div className="flex items-center justify-start gap-x-2">
                          <ReactCountryFlag
                            svg
                            aria-label={iso_code}
                            countryCode={iso_code}
                            style={{
                              width: "1.5rem",
                              height: "1rem",
                            }}
                          />
                          <Text label={destination} />
                        </div>
                      </TableCell>
                      <TableCell width="22%">
                        <Text label={priceInfo(rate_per_min)} weight="medium" />
                      </TableCell>
                      <TableCell width="25%">
                        <Text
                          label={priceInfo(connection_charge)}
                          weight="medium"
                        />
                      </TableCell>
                      <TableCell width="16%">
                        <Text label={`${increment}/${interval}`} />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      ) : null}
    </>
  );
}

export default OutboundPricing;
