import React, { useRef, useState, useContext } from "react";
import { Text, Input, Button, toast } from "samespace-zen";
import {
  IconDash,
  IconEye,
  IconInfo,
  IconTick,
} from "../../../assets/productIcons";
import { Formik } from "formik";
import { VALIDATORS } from "../../../utils/validations";
import * as Yup from "yup";
import { axiosInstance, payloadFromParams } from "../../../utils/request";
import { ReviewDataContext } from "..";

const SetupPassword = () => {
  const { admin_email, product_id } = useContext(ReviewDataContext);
  const [showPassword, setShowPassword] = useState(false);
  const formRef = useRef();
  const [btnStatus, setBtnStatus] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const validationSchema = Yup.object().shape({
    password: VALIDATORS.required,
  });
  const disableSubmit = () => {
    if (formRef.current) {
      const { values } = formRef.current;
      if (
        values?.password?.length > 9 &&
        values?.password?.match(/\d+/) !== null &&
        values?.password?.match(/[a-z]/) !== null &&
        values?.password?.match(/[A-Z]/g) !== null
      ) {
        return false;
      } else return true;
    } else {
      return true;
    }
  };

  const onSetupPassword = (password) => {
    setBtnStatus({ ...btnStatus, loading: true });
    const spaceData = {
      password: password,
    };
    const postApi =
      product_id === 1 ? "customers/voip/user" : "/customers/space/user";
    axiosInstance
      .post(postApi, spaceData, {
        headers: {
          Authorization: `Bearer ${payloadFromParams}`,
        },
      })
      .then(({ data }) => {
        setBtnStatus({ ...btnStatus, loading: false, success: true });
        setTimeout(() => {
          setBtnStatus({ ...btnStatus, success: false });
          window.location.href = data?.data.redirect_uri;
        }, 700);
      })
      .catch((err) => {
        setBtnStatus({ ...btnStatus, loading: false, failure: true });
        toast.error(
          err?.response?.data?.errors?.[0]?.message || "Something went Wrong!"
        );
        setTimeout(() => {
          setBtnStatus({ ...btnStatus, failure: false });
        }, 700);
      });
  };

  return (
    <>
      <div className="pb-8 border-b-[1px] border-solid border-opacity-[0.08] border-black w-full mx-auto my-0">
        <Text label="Setup your password" weight="bold" size="xl" />
      </div>
      <div className="mt-10">
        <Input
          disabled
          value={`You’ll be using ${admin_email} to log in.`}
          prefix={<IconInfo />}
        />
      </div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          password: "",
        }}
        innerRef={formRef}
        render={({
          errors,
          touched,
          values,
          handleSubmit,
          handleBlur,
          handleChange,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mt-6">
              <Input
                label="Password"
                name="password"
                autoFocus
                type={showPassword ? "text" : "password"}
                suffix={
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <IconEye />
                  </div>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched?.password && errors?.password}
                errorText={errors?.password}
                value={values?.password}
              />
            </div>

            <div className="mt-6 flex flex-col gap-y-3">
              <Text
                label="A minimum of 10 characters"
                color="muted"
                icon={
                  values?.password?.length > 9 ? <IconTick /> : <IconDash />
                }
              />
              <Text
                label="At least one number"
                color="muted"
                icon={
                  values?.password?.match(/\d+/) !== null ? (
                    <IconTick />
                  ) : (
                    <IconDash />
                  )
                }
              />
              <Text
                label="At least one lowercase letter"
                color="muted"
                icon={
                  values?.password?.match(/[a-z]/) !== null ? (
                    <IconTick />
                  ) : (
                    <IconDash />
                  )
                }
              />
              <Text
                label="At least one uppercase letter"
                color="muted"
                icon={
                  values?.password?.match(/[A-Z]/g) !== null ? (
                    <IconTick />
                  ) : (
                    <IconDash />
                  )
                }
              />
            </div>
            <div className="mt-10">
              <Button
                disabled={disableSubmit()}
                accent="primary"
                full
                label="Let's Go"
                shape="rounded"
                {...btnStatus}
                onClick={() => {
                  onSetupPassword(values?.password);
                }}
              />
            </div>
            <div className="mt-6">
              <Text
                label={
                  <>
                    <Text
                      label={"By continuing, you’re agreeing to our"}
                      color="muted"
                    />
                    &nbsp;
                    <a
                      href="https://www.samespace.com/legal/terms/"
                      className="text-opacity-1"
                      target="_blank"
                    >
                      <Text label="Terms," color="primary" />
                    </a>
                    &nbsp;
                    <a
                      href="https://www.samespace.com/legal/privacy/"
                      className="text-opacity-1"
                      target="_blank"
                    >
                      <Text label="Privacy Policy," color="primary" />
                    </a>
                    &nbsp; <Text label={"and"} color="muted" />
                    &nbsp;
                    <a
                      href="https://www.samespace.com/legal/cookies/"
                      className="text-opacity-1"
                      target="_blank"
                    >
                      <Text label=" Cookie Policy." color="primary" />
                    </a>
                  </>
                }
                size="sm"
              />
            </div>
          </form>
        )}
      />
    </>
  );
};

export default SetupPassword;
