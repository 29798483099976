import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Text, Button, toast } from "samespace-zen";
import * as html2canvas from "html2canvas";
import { axiosInstance, payloadFromParams } from "../../../utils/request";
import { ReviewDataContext } from "..";
import { delay } from "../../../utils/helpers";

const Terms = ({ setStep = null }) => {
  const {
    order,
    currency_code: currencyCode,
    currency_iso_code: isoCode,
    contract,
    redirect_url,
    admin_name,
    product_id,
  } = useContext(ReviewDataContext);

  const signRef = useRef(admin_name);
  const [terms, setTerms] = useState(null);
  const [btnStatus, setBtnStatus] = useState({
    loading: false,
    success: false,
    error: false,
  });
  const [signatureName, setSignatureName] = useState(admin_name);

  const uuidDocs = useMemo(
    () => contract?.documents?.filter(({ type }) => type === "order")[0]?.uuid,
    [contract?.documents]
  );
  const data = useMemo(() => order?.summary, [order]);

  useEffect(() => {
    if (uuidDocs) {
      getTerms();
    }
    async function getTerms() {
      await axiosInstance
        .get(`legal/account/${uuidDocs}/document`, {
          headers: {
            Authorization: `Bearer ${payloadFromParams}`,
          },
        })
        .then(({ data }) => {
          setTerms(data?.data?.html);
        })
        .catch((error) => {
          toast.error(
            error.response.data.errors?.[0]?.message || "Something went Wrong!"
          );
        });
    }
  }, [uuidDocs]);

  const sendPayload = () => {
    setBtnStatus({ ...btnStatus, loading: true });
    if (contract?.signature) {
      html2canvas(document.querySelector("#signature"), {
        height: 100,
      }).then(function (canvas) {
        document?.body?.appendChild(canvas);
        onPurchase(canvas.toDataURL("image/jpeg"));
        document.body.removeChild(canvas);
      });
    } else {
      onPurchase();
    }
  };

  const onPurchase = (image = null) => {
    const signature =
      image === null
        ? {}
        : {
            signature: image,
          };
    product_id === 1
      ? createVoip()
      : redirect_url
      ? updateSpace(signature)
      : createSpace(signature);
  };

  const createSpace = (signature) => {
    axiosInstance
      .post("/customers/space", signature, {
        headers: {
          Authorization: `Bearer ${payloadFromParams}`,
        },
      })
      .then(({ data }) => {
        setBtnStatus({ ...btnStatus, loading: false, success: true });
        setTimeout(() => {
          setBtnStatus({ ...btnStatus, success: false });
          if (data?.data.user_exists) {
            window.location.href = data?.data.redirect_uri;
          } else {
            setStep(3);
          }
        }, 700);
      })
      .catch((err) => handleError(err));
  };

  const updateSpace = (signature) => {
    axiosInstance
      .post(`/customers/space/edit`, signature, {
        headers: {
          Authorization: `Bearer ${payloadFromParams}`,
        },
      })
      .then(({ data }) => {
        setBtnStatus({ ...btnStatus, loading: false, success: true });
        window.location.href = `${redirect_url}`;
      })
      .catch((err) => handleError(err));
  };

  const createVoip = async () => {
    await delay(600);
    setBtnStatus({ ...btnStatus, loading: false, success: true });
    await delay(800);
    setStep(3);
  };

  const handleError = (err) => {
    setBtnStatus({ ...btnStatus, loading: false, failure: true });
    toast.error(
      err?.response?.data?.errors?.[0]?.message || "Something went Wrong!"
    );
    setTimeout(() => {
      setBtnStatus({ ...btnStatus, failure: false });
    }, 700);
  };

  const orderDetails = (value, label) => {
    return (
      <div className="flex justify-between" key={value}>
        <Text label={label} color="muted" />
        <Text
          label={new Intl.NumberFormat(`en-${isoCode}`, {
            style: "currency",
            currency: currencyCode,
          }).format(Number(value))}
        />
      </div>
    );
  };

  return (
    <div className="w-full mx-auto mt-16 mb-0">
      <div className="pb-4 border-b-[1px] border-solid border-opacity-[0.08] border-black">
        <div className="flex flex-col">
          <Text label="Ready to buy" weight="medium" size="xl" color="muted" />
          <Text label="What you need to know" size="sm" color="muted" />
        </div>
      </div>
      <div className="flex justify-between py-5 ">
        <div
          className="orderHTML-container w-full"
          dangerouslySetInnerHTML={{ __html: terms }}
        />
      </div>
      {contract?.signature ? (
        <>
          <div className="mt-10 relative h-20">
            <span
              contentEditable
              id="signature"
              className="flex w-fit h-fit relative -top-0.5 items-center justify-center font-dancing-script  outline-none text-6xl indent-1 px-2 mb-2"
              suppressContentEditableWarning={true}
              onInput={(e) => setSignatureName(e.currentTarget.textContent)}
            >
              {signRef.current}
            </span>
          </div>
          <div className="pt-4 mb-8 border-t-[1px] border-dashed border-opacity-[0.16] border-black">
            <Text label={`Sign in as ${admin_name}`} color="muted" />
          </div>
        </>
      ) : null}

      {data && (
        <div className="border-[1px] border-solid border-opacity-[0.08] border-black bg-section p-8 flex flex-col gap-y-5 rounded-lg mb-16">
          <Text label={"Order Summary"} weight="medium" size="lg" />
          <div className="flex flex-col gap-y-5">
            {Object.values(data.amount).map((value, id) => {
              const labels = ["Sub Total", "Professional Services", "Tax"];
              return orderDetails(value, labels[id]);
            })}
          </div>
          <div className="border-t-[1px] border-solid border-opacity-[0.08] border-black pt-6 flex justify-between">
            <Text label={"Total Due"} />
            <Text
              label={new Intl.NumberFormat(`en-${isoCode}`, {
                style: "currency",
                currency: currencyCode,
              }).format(Number(data.total))}
              size="lg"
              weight="bold"
            />
          </div>
        </div>
      )}
      <Button
        accent="primary"
        full
        disabled={contract?.signature && signatureName.length === 0}
        label="Purchase"
        shape="rounded"
        onClick={sendPayload}
        {...btnStatus}
      />
    </div>
  );
};

export default Terms;
