import React from "react";
import {
  IconChatBubble,
  IconChatWave,
  IconCode,
  IconEmail,
  IconFolder,
  IconHook,
  IconPhoneWave,
  IconUsersTwo,
  IconWave,
} from "./icons";

export const MAX_LENGTHS = {
  name: 30,
  description: 240,
};

export function getPrivilegeShow(item) {
  let create = false;
  let update = false;
  let remove = false;

  if (item === 3 || item === 7 || item === 11 || item === 15) {
    create = true;
  }
  if (item === 5 || item === 7 || item === 13 || item === 15) {
    update = true;
  }
  if (item === 9 || item === 11 || item === 13 || item === 15) {
    remove = true;
  }
  return {
    create,
    update,
    remove,
  };
}

export const PRODUCT_ICONS = {
  5: { name: "Users", icon: <IconUsersTwo />, bgColor: "#5345F6" },
  6: { name: "VAPhone", icon: <IconWave />, bgColor: "#14937C" },
  7: { name: "VAChat", icon: <IconChatBubble />, bgColor: "#0057FF" },
  8: { name: "SAPhone", icon: <IconPhoneWave />, bgColor: "#14937C" },
  9: { name: "SAMessaging", icon: <IconChatWave />, bgColor: "#0057FF" },
  10: { name: "STT", icon: <IconChatBubble />, bgColor: "#0057FF" },
  11: { name: "TTS", icon: <IconWave />, bgColor: "#14937C" },
  12: { name: "Functions", icon: <IconCode />, bgColor: "#5345F6" },
  13: { name: "Webhooks", icon: <IconHook />, bgColor: "#EF118B" },
  14: { name: "PS", icon: <IconEmail />, bgColor: "#C521E0" },
  15: { name: "Storage", icon: <IconFolder />, bgColor: "#FF9000" },
  22: { name: "Users", icon: <IconUsersTwo />, bgColor: "#5345F6" },
};
