import React, { useContext } from "react";
import {
  Text,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tag,
} from "samespace-zen";
import { ReviewDataContext } from "..";
import ReactCountryFlag from "react-country-flag";
import { payloadFromParams } from "../../../utils/request";
import classNames from "classnames";
import TableHeader from "./TableHeader";

function TollFreePricing() {
  const {
    pricing,
    currency_iso_code,
    currency_code,
    rates: defaultRates,
  } = useContext(ReviewDataContext);
  const data = pricing?.toll_free_price;

  const priceInfo = (rate) => {
    let price = new Intl.NumberFormat(`en-${currency_iso_code}`, {
      style: "currency",
      currency: currency_code,
    }).format(+rate);
    return price.replace(/\.00$/, "");
  };

  return (
    <>
      {(data || defaultRates)?.length ? (
        <div className="w-full mx-auto mt-12 mb-0">
          <div className="pb-4 flex items-center justify-between">
            <Text
              label="Toll Free Pricing"
              weight="medium"
              size="xl"
              color="muted"
            />
            <Text
              label="View Default Rates"
              color="primary"
              size="sm"
              className={classNames("cursor-pointer", {
                hidden: defaultRates?.length,
              })}
              onClick={() =>
                window.open(
                  `/default/tollfree?payload=${payloadFromParams}`,
                  "_blank"
                )
              }
            />
          </div>
          <Table>
            <TableHeader
              columns={["country", "mrc", "nrc", "allow origin"]}
              colSizes={["35%", "25%", "25%", "15%"]}
            />
            <TableBody>
              {(data || defaultRates).map(
                ({ country_iso, country, mrc, nrc, allow_origin }) => {
                  return (
                    <TableRow key={country_iso}>
                      <TableCell className="pl-2" width="35%">
                        <div className="flex items-center justify-start gap-x-2">
                          <ReactCountryFlag
                            svg
                            aria-label={country_iso}
                            countryCode={country_iso}
                            style={{
                              width: "1.5rem",
                              height: "1rem",
                            }}
                          />
                          <Text label={country} />
                        </div>
                      </TableCell>
                      <TableCell width="25%">
                        <Text label={priceInfo(mrc)} weight="medium" />
                      </TableCell>
                      <TableCell width="25%">
                        <Text label={priceInfo(nrc)} weight="medium" />
                      </TableCell>
                      <TableCell width="15%">
                        <Tag
                          label={allow_origin}
                          appearance="tinted"
                          shape="rounded"
                          accent={allow_origin === "Yes" ? "success" : "danger"}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      ) : null}
    </>
  );
}

export default TollFreePricing;
