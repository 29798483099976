import React, { useContext } from "react";
import { Text, Table, TableBody, TableRow, TableCell } from "samespace-zen";
import { ReviewDataContext } from "..";
import ReactCountryFlag from "react-country-flag";
import TableHeader from "./TableHeader";

function TollFreeSourcePricing() {
  const { pricing, currency_iso_code, currency_code } =
    useContext(ReviewDataContext);
  const data = pricing?.toll_free_source_price;

  const priceInfo = (rate) => {
    let price = new Intl.NumberFormat(`en-${currency_iso_code}`, {
      style: "currency",
      currency: currency_code,
    }).format(+rate);
    return price.replace(/\.00$/, "");
  };

  return (
    <>
      {data?.length ? (
        <div className="w-full mx-auto mt-12 mb-0">
          <div className="pb-4 flex items-center justify-between">
            <Text
              label="Toll Free Source Pricing"
              weight="medium"
              size="xl"
              color="muted"
            />
          </div>
          <Table>
            <TableHeader
              columns={[
                "destination",
                "rate/min",
                "connection charge",
                "incrementals",
              ]}
              colSizes={[null, "22%", "25%", "16%"]}
            />
            <TableBody>
              {data.map(
                ({
                  country_iso,
                  source_destination,
                  rate_per_min,
                  connection_charge,
                  increment,
                  interval,
                }) => {
                  return (
                    <TableRow key={country_iso}>
                      <TableCell className="pl-2">
                        <div className="flex items-center justify-start gap-x-2">
                          <ReactCountryFlag
                            svg
                            aria-label={country_iso}
                            countryCode={country_iso}
                            style={{
                              width: "1.5rem",
                              height: "1rem",
                            }}
                          />
                          <Text label={source_destination} />
                        </div>
                      </TableCell>
                      <TableCell width="22%">
                        <Text label={priceInfo(rate_per_min)} weight="medium" />
                      </TableCell>
                      <TableCell width="25%">
                        <Text
                          label={priceInfo(connection_charge)}
                          weight="medium"
                        />
                      </TableCell>
                      <TableCell width="16%">
                        <Text label={`${increment}/${interval}`} />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      ) : null}
    </>
  );
}

export default TollFreeSourcePricing;
