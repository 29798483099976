import React from "react";
import { IconThankyou } from "../../assets/productIcons";
import { Text } from "samespace-zen";
import OnboardHeader from "../../components/OnboardHeader";

const Thankyou = ({ title }) => {
  return (
    <>
      <div className="absolute top-0 w-full">
        <OnboardHeader showProductName={false} leftAlign={false} />
      </div>
      <section className="flex flex-col	items-center w-[640px] mx-auto justify-center flex-1">
        <IconThankyou />
        <Text label={title} size="lg" weight="medium" />
        <Text
          label="We will get back to you if we need more information, please keep an eye on your inbox. We’re very excited to have you onboard and looking forward to serve you."
          color="muted"
          className="text-center mt-5"
        />
      </section>
    </>
  );
};

export default Thankyou;
