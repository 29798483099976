import axios from "axios";
import React from "react";
import { toast } from "samespace-zen";

import { API_BASE } from "./urls";

////////////////////////
///// set defaults /////
////////////////////////
export function setAxiosDefaults({ hubToken }) {
  axios.defaults.withCredentials = false;
  axios.defaults.baseURL = API_BASE;
  axios.defaults.headers["Authorization"] = `JWT ${hubToken}`;
}

///////////////////////
///// get         /////
///////////////////////
export function requestGetFile(url) {
  return axios
    .get(url, { responseType: "blob" })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      toast.error("Something went wrong!");
    });
}

export function requestGet(url) {
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      toast.error("Something went wrong!");
    });
}
export function requestGetWithError(url) {
  return axios.get(url).then((response) => {
    return response.data;
  });
  // .catch((e) => {
  //   console.log('Wrong')
  // })
}
///////////////////////
///// post or put /////
///////////////////////

export function requestUpdate({
  method,
  url,
  data,
  config,
  errorMessage,
  responseType,
}) {
  return new Promise(function (fulfill, reject) {
    axios({
      method,
      url,
      data,
      config,
      ...(responseType && { responseType }),
    })
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
          try {
            fulfill(response.data);
          } catch (e) {
            toast.error(
              response.data || errorMessage || "Something went wrong!"
            );
            reject(response.data);
          }
        } else {
          toast.error(response.data || errorMessage || "Something went wrong!");
          reject(response.data);
        }
      })
      .catch((error) => {
        try {
          if (error.response.data.errors) {
            error.response.data.errors.map((error) => {
              return toast.error(error.message);
            });
          } else {
            toast.error(error.data || errorMessage || "Something went wrong!");
          }
        } catch (e) {
          toast.error("Something went wrong!");
        }
        reject(error.data);
      });
  });
}

//////////////////
///// delete /////
//////////////////

export function requestDelete(url, data) {
  return new Promise(function (fulfill, reject) {
    axios
      .delete(url, data)
      .then((response) => {
        if (response.status === 200) {
          try {
            fulfill(response.data);
          } catch (e) {
            toast.error("Something went wrong!");
          }
        } else {
          toast.error("Something went wrong!");
        }
      })
      .catch((error) => {
        toast.error(error.data || "Something went wrong!");
      });
  });
}

///////////////////////
/// axios instance  ///
///////////////////////

export const axiosInstance = axios.create({
  baseURL: `${
    process.env.REACT_APP_HUB_BASE_URL || "https://api.ss.dev"
  }/hub/v2`,
});

const getPayload = () => {
  let params = new URLSearchParams(window.location.search);
  return params.get("payload");
};
export const payloadFromParams = getPayload();
