import React, { useContext } from "react";
import { Text } from "samespace-zen";
import { ReviewDataContext } from "..";
import { PRODUCT_ICONS } from "../../../utils/constants";

const Metered = () => {
  const {
    order,
    currency_code: currencyCode,
    currency_iso_code: isoCode,
  } = useContext(ReviewDataContext);
  const data = order?.metered;

  const priceInfo = (label, value) => {
    return `per ${value} ${label}`;
  };

  return (
    <div className="w-full mx-auto mt-16 mb-0">
      {data?.length ? (
        <div className="pb-4 border-b-[1px] border-solid border-opacity-[0.08] border-black">
          <Text label="Metered" weight="medium" size="xl" color="muted" />
        </div>
      ) : null}
      {data?.map(
        (
          {
            product_name,
            quantity,
            mrc,
            unit_value,
            unit_label,
            sub_product_id,
          },
          id
        ) => {
          return (
            <div
              className="flex justify-between py-5 border-b-[1px] border-solid border-opacity-[0.08] border-black"
              key={id}
            >
              <div className="flex items-center gap-x-4">
                <div
                  className="rounded-full flex justify-center items-center w-8 h-8 min-w-8"
                  style={{
                    backgroundColor: PRODUCT_ICONS[sub_product_id]?.bgColor,
                  }}
                >
                  {PRODUCT_ICONS[sub_product_id]?.icon}
                </div>
                <Text label={product_name} weight="bold" />
              </div>
              <div>
                <Text
                  label={new Intl.NumberFormat(`en-${isoCode}`, {
                    style: "currency",
                    currency: currencyCode,
                  }).format(+mrc)}
                  weight="medium"
                  className="mr-1"
                />
                <Text label={priceInfo(unit_label, unit_value)} color="muted" />
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default Metered;
