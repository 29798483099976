import React, { useEffect, useState } from "react";
import { Loader } from "samespace-zen";
import CustomerForm from "./CustomerForm";
import Thankyou from "./Thankyou";
import ErrorPage from "../../components/ErrorPage";
import { axiosInstance, payloadFromParams } from "../../utils/request";

export default function Onboarding() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [docs, setDocs] = useState([]);
  const [email, setEmail] = useState("");
  const [newFormCreated, setNewFormCreated] = useState(false);
  const [formExists, setFormExists] = useState(false);

  useEffect(() => {
    axiosInstance
      .get("/customers/onboard", {
        headers: {
          Authorization: `Bearer ${payloadFromParams}`,
        },
      })
      .then(({ data }) => {
        setDocs(data?.data?.kyc_documents);
        setEmail(data?.data?.email);
        setLoading(false);
      })
      .catch((e) => {
        const err = e?.response?.data?.errors?.findIndex(
          (item) => item.message === "Invite Expired or Revoked"
        );
        if (e?.response?.data === undefined) {
          setError(true);
          setFormExists(false);
        }
        if (err !== -1) {
          setError(false);
          setFormExists(true);
        } else {
          setFormExists(false);
          setError(true);
        }

        setLoading(false);
      });
  }, []);
  return (
    <>
      {error && <ErrorPage />}
      {loading && (
        <div className="h-full w-full flex justify-center items-center">
          <Loader />
        </div>
      )}
      {!error && !loading && (
        <div className="h-full">
          <div className="w-full h-full flex flex-col">
            {!loading && !newFormCreated && !formExists && (
              <CustomerForm
                documentsRequired={docs}
                setNewFormCreated={setNewFormCreated}
                email={email}
              />
            )}
            {!loading && (newFormCreated || formExists) && (
              <Thankyou
                title={
                  newFormCreated
                    ? "Thanks for signing up for Samespace"
                    : "Account already exist"
                }
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
