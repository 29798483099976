import React, { createContext, useCallback, useEffect, useState } from "react";
import { axiosInstance, payloadFromParams } from "../../utils/request";
import { Loader } from "samespace-zen";
import SpaceProduct from "./SpaceProduct";
import VoipProduct from "./VoipProduct";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";
import OnboardHeader from "../../components/OnboardHeader";
import SetupPassword from "./shared/SetupPassword";
import Terms from "./shared/Terms";
import classNames from "classnames";
import LocalPricing from "./VoipProduct/LocalPricing";
import TollFreePricing from "./VoipProduct/TollFreePricing";
import OutboundPricing from "./VoipProduct/OutboundPricing";

export const ReviewDataContext = createContext();

const ReviewOrder = () => {
  const [reviewData, setReviewData] = useState([]);
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);

  const getApi = useCallback(() => {
    let api;
    switch (window.location.pathname) {
      case "/subscription":
        api = "https://api.ss.dev/hub/v2/customers/space/edit";
        break;
      case "/new_voip/create":
        api = "/customers/voip/validate";
        break;
      case "/new_space/create":
        api = "/customers/space/validate";
        break;
      case "/default/local":
        api = "/customers/voip/local-number/default";
        break;
      case "/default/tollfree":
        api = "/customers/voip/toll-free/default";
        break;
      case "/default/outbound":
        api = "/customers/voip/outbound/default";
        break;
      default:
        break;
    }
    return api;
  }, [window.location]);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(getApi(), {
        headers: {
          Authorization: `Bearer ${payloadFromParams}`,
        },
      })
      .then(({ data }) => {
        setReviewData(data?.data);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {error && <ErrorPage />}
      {!error && loading && (
        <div className="h-full w-full flex justify-center items-center">
          <Loader className="h-full" />
        </div>
      )}
      {!error && !loading && (
        <ReviewDataContext.Provider value={reviewData}>
          <OnboardHeader
            showProductName={true}
            productName={
              reviewData?.product_name ||
              (reviewData?.product_id === 1 && "Voice")
            }
            width={
              reviewData?.product_id === 1 && step !== 3 ? "720px" : "520px"
            }
          />
          {step === 1 && (
            <div
              className={classNames(
                "w-[520px] flex-col items-center pb-8 mt-14 mx-auto",
                { "w-[720px]": reviewData?.product_id === 1 }
              )}
            >
              <Switch>
                <Route path={["/new_space", "/subscription"]}>
                  <SpaceProduct />
                </Route>
                <Route path="/new_voip">
                  <VoipProduct />
                </Route>
                <Route path="/default/local">
                  <LocalPricing />
                </Route>
                <Route path="/default/tollfree">
                  <TollFreePricing />
                </Route>
                <Route path="/default/outbound">
                  <OutboundPricing />
                </Route>
              </Switch>
              {reviewData?.product_name ? <Terms setStep={setStep} /> : null}
            </div>
          )}
          {step === 3 && (
            <div className="w-[520px] flex-col items-center pb-8 mt-14 mx-auto">
              <SetupPassword />
            </div>
          )}
          {/* {step === 2 && <Payments token={tokenId} customer={customerId} name={name}/>} */}
        </ReviewDataContext.Provider>
      )}
    </>
  );
};

export default ReviewOrder;
