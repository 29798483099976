import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Select,
  Loader,
  toast,
  Checkbox,
  Text,
  FileUploader,
  Button,
  Tooltip,
} from "samespace-zen";
import { Formik } from "formik";
import * as Yup from "yup";
import { VALIDATORS } from "../../utils/validations";
import { IconInfo } from "../../assets/productIcons";
import { isEmpty } from "lodash";
import "../../assets/styles.scss";
import OnboardHeader from "../../components/OnboardHeader";
import { axiosInstance, payloadFromParams } from "../../utils/request";

function CustomerForm({ documentsRequired, email, setNewFormCreated }) {
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryObject, setCountryObject] = useState("");
  const [stateObject, setStateObject] = useState("");
  const [billingSame, isBillingSame] = useState(true);
  const [files, setFiles] = useState([]);
  const formRef = useRef();
  const [btnStatus, setBtnStatus] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const validationShape = {
    business_details: Yup.object().shape({
      business_name: VALIDATORS.required,
      address_line_1: VALIDATORS.required,
      country_id: VALIDATORS.required,
      zip: Yup.number("should be number")
        .positive("Should be positive value")
        .required("Required")
        .test("len", "Must be between 1-6 characters", (val) =>
          val ? val.toString().length > 0 && val.toString().length < 7 : false
        ),
      website: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url"
      ),
      city: VALIDATORS.required,
    }),
    account_owner: Yup.object().shape({
      first_name: VALIDATORS.required,
      last_name: VALIDATORS.required,
      email: VALIDATORS.requiredEmail,
      phone: Yup.string()
        .required("Must enter a phone number")
        .matches(
          /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/,
          "Enter Valid Phone Number"
        ),
    }),
  };
  const validationSchema = Yup.object().shape(
    billingSame
      ? validationShape
      : {
          ...validationShape,
          billing_contact: Yup.object().shape({
            first_name: VALIDATORS.required,
            last_name: VALIDATORS.required,
            email: VALIDATORS.requiredEmail,
            phone: Yup.string()
              .required("Must enter a phone number")
              .matches(
                /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/,
                "Enter Valid Phone Number"
              ),
          }),
        }
  );
  const initialValues = {
    business_details: {
      business_name: "",
      address_line_1: "",
      address_line_2: "",
      country_id: "",
      zip: "",
      city: "",
      state_id: "",
      website: "",
    },
    account_owner: {
      first_name: "",
      last_name: "",
      email: email || "",
      phone: "",
    },
    billing_contact: {
      first_name: "",
      last_name: "",
      email: email || "",
      phone: "",
    },
  };

  useEffect(() => {
    axiosInstance.get("/onboarding/form?filter=countries").then(({ data }) => {
      const countriesCopy = data.data.countries.filter((item) => item.id !== 0);
      setCountryOptions([
        ...countriesCopy.map(({ id, country, iso_code }) => ({
          value: id,
          label: country,
          iso_code,
        })),
      ]);
    });
    //getStates(data.country_id)
  }, []);

  function getStates(id) {
    axiosInstance.get(`countries/${id}/states`).then(({ data }) => {
      const statesCopy = data.data.filter((item) => item.state !== "All");
      setStateOptions([
        ...statesCopy.map((item) => {
          return {
            value: item.id,
            label: item.state,
          };
        }),
      ]);
    });
  }

  const documentsLabel = () => {
    if (documentsRequired?.length > 1) {
      return `Upload Certificate of ${documentsRequired.map(
        (i) => ` ${i}`
      )} to verify your business. `;
    }
    if (documentsRequired?.length === 1) {
      return `Upload Certificate of ${documentsRequired[0]} to verify your business. `;
    } else {
      return `Upload Documents to verify your business. `;
    }
  };

  const submitForm = () => {
    let docString = `Upload Documents to verify your business. `;
    let documentsValidation = documentsLabel() !== docString && !files.length;
    if (formRef?.current) {
      const { validateForm, setFieldTouched, values } = formRef.current;
      validateForm().then((errors) => {
        Object.keys(errors).map((item) => {
          setFieldTouched(`${item}`);
        });
        if (documentsValidation) {
          toast.error("Upload Documents");
        }

        if (isEmpty(errors) && !documentsValidation) {
          let formData = new FormData();
          const { billing_contact, account_owner, ...rest } = values;

          const payload = {
            billing_contact: billingSame
              ? { ...account_owner, phone: account_owner.phone.toString() }
              : { ...billing_contact, phone: billing_contact.phone.toString() },
            account_owner: {
              ...account_owner,
              phone: account_owner.phone.toString(),
            },
            ...rest,
          };
          formData.append("data", JSON.stringify(payload));
          files.forEach((item) => {
            formData.append("files", item);
          });
          onSubmit(formData);
        }
      });
    }
  };

  const onSubmit = (formData) => {
    setBtnStatus({ ...btnStatus, loading: true });
    axiosInstance
      .post(`/customers/onboard/add`, formData, {
        headers: {
          Authorization: `Bearer ${payloadFromParams}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setBtnStatus({ ...btnStatus, loading: false, success: true });
        setTimeout(() => {
          setBtnStatus({ ...btnStatus, loading: false, success: false });
          formRef.current.resetForm();
          setCountryObject("");
          setStateObject("");
          setFiles([]);
          setNewFormCreated(true);
        }, 700);
      })
      .catch((err) => {
        setBtnStatus({ ...btnStatus, loading: false, failure: true });
        setNewFormCreated(false);
        toast.error(
          err?.response?.data?.errors?.[0]?.message || "Something went Wrong!"
        );
        setTimeout(() => {
          setBtnStatus({ ...btnStatus, failure: false });
        }, 700);
      });
  };

  return (
    <>
      <OnboardHeader showProductName={false} leftAlign={true} />
      <div className="w-[520px] mt-14 mx-auto pb-10">
        <div className="pb-5 border-b-[1px] border-solid border-opacity-[0.08] border-black">
          <Text
            label="Tell us a bit about your business"
            weight="bold"
            size="xl"
          />
        </div>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          innerRef={formRef}
          render={({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            resetForm,
            dirty,
            handleBlur,
            handleChange,
            isValid,
          }) => (
            <>
              <div className="w-full">
                <section className="my-10 grid grid-cols-1 space-y-8">
                  <article className="grid grid-cols-1">
                    <Input
                      label="Business Name"
                      name="business_details.business_name"
                      placeholder="Name"
                      onChange={handleChange}
                      value={values?.business_details?.business_name}
                      onBlur={handleBlur}
                      error={
                        touched?.business_details?.business_name &&
                        errors?.business_details?.business_name
                      }
                      errorText={errors?.business_details?.business_name}
                      autoFocus
                    />
                  </article>
                  <article className="grid grid-cols-1">
                    <Input
                      label="Address Line 1"
                      name="business_details.address_line_1"
                      placeholder="Address"
                      onChange={handleChange}
                      value={values?.business_details?.address_line_1}
                      onBlur={handleBlur}
                      error={
                        touched?.business_details?.address_line_1 &&
                        errors?.business_details?.address_line_1
                      }
                      errorText={errors?.business_details?.address_line_1}
                    />
                  </article>
                  <article className="grid grid-cols-1">
                    <Input
                      label="Address Line 2 (Optional)"
                      name="business_details.address_line_2"
                      placeholder="Address"
                      onChange={handleChange}
                      value={values?.business_details?.address_line_2}
                      onBlur={handleBlur}
                      error={
                        touched?.business_details?.address_line_2 &&
                        errors?.business_details?.address_line_2
                      }
                      errorText={errors?.business_details?.address_line_2}
                    />
                  </article>
                  <article className="grid grid-cols-2 gap-x-8">
                    <Select
                      name="business_details.country_id"
                      value={countryObject}
                      onChange={(e) => {
                        setCountryObject(e.target.value);
                        setFieldValue(
                          "business_details.country_id",
                          e.target.value.value
                        );
                        getStates(e.target.value.value);
                      }}
                      options={countryOptions}
                      label="Country"
                      onBlur={handleBlur}
                      error={
                        touched?.business_details?.country_id &&
                        errors?.business_details?.country_id
                      }
                      errorText={errors?.business_details?.country_id}
                    />
                    <div className="reset-input-num-arrows">
                      <Input
                        name="business_details.zip"
                        label="ZIP Code"
                        type="number"
                        min={0}
                        value={values?.business_details?.zip}
                        onChange={handleChange}
                        onBlur={(e) => {
                          setFieldValue(
                            "business_details.zip",
                            Number(e.target.value)
                          );
                          setFieldTouched("business_details.zip", true);
                        }}
                        error={
                          touched?.business_details?.zip &&
                          errors?.business_details?.zip
                        }
                        errorText={
                          <div className="text-clip overflow-hidden whitespace-nowrap w-[215px]">
                            {errors?.business_details?.zip}
                          </div>
                        }
                      />
                    </div>
                  </article>
                  <article className="grid grid-cols-2 gap-x-8">
                    <Input
                      name="business_details.city"
                      value={values?.business_details?.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched?.business_details?.city &&
                        errors?.business_details?.city
                      }
                      errorText={errors?.business_details?.city}
                      label="City"
                    />
                    <Select
                      name="business_details.state_id"
                      value={stateObject}
                      onChange={(e) => {
                        setStateObject(e.target.value);
                        setFieldValue(
                          "business_details.state_id",
                          e.target.value.value
                        );
                      }}
                      options={stateOptions}
                      label="State"
                      onBlur={handleBlur}
                      error={
                        touched?.business_details?.state_id &&
                        errors?.business_details?.state_id
                      }
                      errorText={errors?.business_details?.state_id}
                    />
                  </article>
                  <article className="grid grid-cols-1">
                    <Input
                      label="Website"
                      name="business_details.website"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched?.business_details?.website &&
                        errors?.business_details?.website
                      }
                      errorText={errors?.business_details?.website}
                      value={values?.business_details?.website}
                    />
                  </article>
                  <article className="grid grid-cols-1 gap-y-4">
                    <Input
                      disabled
                      label="Documents"
                      value={documentsLabel()}
                      prefix={
                        <Tooltip title={documentsLabel()} position="top">
                          <span className="cursor-pointer">
                            <IconInfo />
                          </span>
                        </Tooltip>
                      }
                      inputClassName="truncate"
                    />
                    <FileUploader
                      accept="application/pdf"
                      title="Drag and drop or choose a file to upload documents"
                      files={files}
                      multiple
                      onSelect={(files) => {
                        if (documentsRequired?.length) {
                          if (files.length <= documentsRequired?.length)
                            setFiles(files);
                          else {
                            toast.error("no. of files limit exceeded");
                          }
                        } else setFiles(files);
                      }}
                      onDelete={(deletedFile) => {
                        const updatedFiles = files.filter(
                          (file) => file.name !== deletedFile.name
                        );
                        setFiles(updatedFiles);
                      }}
                    />
                  </article>
                </section>
              </div>
              <div className="pb-5 border-b-[1px] border-solid border-opacity-[0.08] border-black mt-16">
                <Text label="Account Owner" weight="bold" size="xl" />
              </div>
              <div className="w-full">
                <section className="my-10 grid grid-cols-1 space-y-8">
                  <article className="grid grid-cols-2 gap-x-8">
                    <Input
                      name="account_owner.first_name"
                      label="First Name"
                      value={values?.account_owner?.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched?.account_owner?.first_name &&
                        errors?.account_owner?.first_name
                      }
                      errorText={errors?.account_owner?.first_name}
                    />
                    <Input
                      label="Last Name"
                      name="account_owner.last_name"
                      value={values?.account_owner?.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched?.account_owner?.last_name &&
                        errors?.account_owner?.last_name
                      }
                      errorText={errors?.account_owner?.last_name}
                    />
                  </article>
                  <article className="grid grid-cols-2 gap-x-8 reset-input-num-arrows">
                    <Input
                      name="account_owner.email"
                      //onBlur={handleBlur}
                      label="Email"
                      value={values?.account_owner?.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched?.account_owner?.email &&
                        errors?.account_owner?.email
                      }
                      errorText={errors?.account_owner?.email}
                    />
                    <Input
                      name="account_owner.phone"
                      label="Mobile"
                      type="number"
                      value={values?.account_owner?.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched?.account_owner?.phone &&
                        errors?.account_owner?.phone
                      }
                      errorText={errors?.account_owner?.phone}
                    />
                  </article>
                  <article className="grid grid-cols-1">
                    <Checkbox
                      label="Billing Contact - same as account owner"
                      placeholder="Name"
                      onChange={(e) => {
                        isBillingSame(e.target.checked);
                      }}
                      checked={billingSame}
                    />
                  </article>
                </section>
              </div>
              {!billingSame && (
                <>
                  <div className="pb-5 border-b-[1px] border-solid border-opacity-[0.08] border-black mt-16">
                    <Text label="Billing Contact" weight="bold" size="xl" />
                  </div>
                  <div className="w-full">
                    <section className="my-10 grid grid-cols-1 space-y-8">
                      <article className="grid grid-cols-2 gap-x-8">
                        <Input
                          name="billing_contact.first_name"
                          label="First Name"
                          value={values?.billing_contact?.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched?.billing_contact?.first_name &&
                            errors?.billing_contact?.first_name
                          }
                          errorText={errors?.billing_contact?.first_name}
                        />
                        <Input
                          label="Last Name"
                          name="billing_contact.last_name"
                          value={values?.billing_contact?.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched?.billing_contact?.last_name &&
                            errors?.billing_contact?.last_name
                          }
                          errorText={errors?.billing_contact?.last_name}
                        />
                      </article>
                      <article className="grid grid-cols-2 gap-x-8 reset-input-num-arrows">
                        <Input
                          name="billing_contact.email"
                          label="Email"
                          value={values?.billing_contact?.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched?.billing_contact?.email &&
                            errors?.billing_contact?.email
                          }
                          errorText={errors?.billing_contact?.email}
                        />
                        <Input
                          name="billing_contact.phone"
                          label="Mobile"
                          type="number"
                          value={values?.billing_contact?.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched?.billing_contact?.phone &&
                            errors?.billing_contact?.phone
                          }
                          errorText={errors?.billing_contact?.phone}
                        />
                      </article>
                    </section>
                  </div>
                </>
              )}
              <div className="border-t-[1px] border-solid border-opacity-[0.08] border-black pt-8 flex justify-end mb-8">
                <div className="w-28">
                  <Button
                    accent="primary"
                    label="Create"
                    shape="rounded"
                    full
                    disabled={!(dirty && isValid)}
                    {...btnStatus}
                    onClick={submitForm}
                  />
                </div>
              </div>
            </>
          )}
        />
      </div>
    </>
  );
}

export default CustomerForm;
