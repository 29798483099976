import React from "react";
import { Text, TableHead, TableRow, TableCell } from "samespace-zen";

function TableHeader({ columns, colSizes }) {
  return (
    <TableHead className="bg-section border-tableHead border-solid border-x-[1px] border-t-[1px] rounded-md">
      <TableRow>
        {columns.map((label, i) => {
          let colStyles = { width: colSizes?.length ? colSizes[i] : "auto" };
          return (
            <TableCell className={i === 0 && "pl-2"} {...colStyles} key={i}>
              <Text
                label={label}
                size="2xs"
                color="muted"
                case="uppercase"
                weight="medium"
              />
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
